<template>
  <div v-if="userIsCrupier" style="overflow: hidden" class="row">
    <vs-button @click="handleMenu" class="w-25">Seleccionar vista</vs-button>
    <Chat v-if="viewSelected === 'chat'" />
    <CounterDragon v-else-if="viewSelected === 'dragonCounter'"
      >Counter</CounterDragon
    >
    <RoundHandler v-else-if="viewSelected === 'wheel'" />
    <div v-else class="mx-auto d-flex justify-content-center my-5">
      <p>Seleccione una vista en el menu por favor...</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Chat from '../chat/components/Chat.vue';
import CounterDragon from '../dashboard/components/CounterDragon.vue';
import RoundHandler from '../games/wheels/RoundHandler.vue';

export default {
  props: {
    role: {
      type: String,
      default: () => '',
    },
  },
  components: { Chat, CounterDragon, RoundHandler },
  data() {
    return {
      viewSelected: null,
    };
  },
  computed: {
    ...mapGetters({
      getViewSelected: 'crupiers/getViewSelected',
      userIsCrupier: 'user/isCrupier',
    }),
  },
  methods: {
    async handleMenu() {
      await this.handleMenuOptions();

      if (this.getViewSelected === 'ranking')
        return this.$router.push({ name: 'RouletteRanking' });
      if (this.getViewSelected === 'roulette') {
        await this.handleRouletteOptions();
        this.goManualResult();
        // return this.$router.push({ name: 'RouletteRanking' });
        return;
      }

      this.viewSelected = this.getViewSelected;
    },
    ...mapActions({
      handleMenuOptions: 'crupiers/handleMenuOptions',
      handleRouletteOptions: 'crupiers/handleRouletteOptions',
      goManualResult: 'games/goManualResult',
    }),
  },
  mounted() {
    if (this.userIsCrupier) this.handleMenu();
  },
};
</script>
