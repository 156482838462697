<template>
  <div class="row py-4">
    <div class="col-3">
      <b-card
        v-for="(game, i) in games"
        :key="i"
        @click="handleSelect(game)"
        style="cursor: pointer"
      >
        Mesa: <strong>{{ game.name }}</strong>
      </b-card>
    </div>
    <h1 ref="winnerResult" class="text-center winner-result" v-if="winner">
      {{ winnerTypes[winner] }}
    </h1>

    <div class="col-7">
      <b-card>
        <template v-if="gameSelected">
          <p>
            Mesa seleccionada: <strong>{{ gameSelected.name }}</strong>
          </p>
          <div>
            <h2 class="text-center fs-1">
              Tiempo para cerrar ronda: {{ timer }}...
            </h2>
            <h3
              class="fs-2 p-2"
              :class="timer > 10 ? 'bg-success' : 'bg-danger'"
              v-if="timer"
            >
              {{ timer > 10 ? 'Apuestas abiertas' : 'Ultimas apuestas' }}
            </h3>
          </div>
          <div class="mt-4" v-if="currentCard">
            <h2 class="text-center">
              Carta {{ cardType }}{{ burned ? ' quemada' : '' }}:
            </h2>
            <div class="card-accepted-container" :style="`color:${color}`">
              <div class="card-accepted">
                <span class="card-left-number">{{ cardName }}</span>
                <span class="card-type">{{ cardTypes[type] }}</span>
                <span class="card-right-number">{{ cardName }}</span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <span>Seleccione una mesa</span>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { io } from 'socket.io-client';

export default {
  data() {
    return {
      games: [],
      gameSelected: null,
      counter: 10,
      color: 'black',
      cardName: 'A',
      type: 'trebol',
      cardTypes: {
        trebol: '♣',
        pica: '♠',
        corazon: '♥',
        diamante: '♦',
      },

      socket: null,
      SOCKET_URL: '',
      gameId: '63ef9a172ef4d186807f80d5',
      gameUuid: '123456',
      PLAYER_ID: '123456',
      totalTime: 0,
      timer: 0,
      roundClosed: false,
      roundOpened: false,
      runningInterval: false,

      currentCard: false,

      interval: false,
      timeToInterval: 1000,

      // WINNTER TYPES: tie, perfectTie, dragon, tiger
      winnerTypes: {
        tie: 'EMPATE',
        perfectTie: 'EMPATE PERFECTO',
        dragon: 'DRAGON',
        tiger: 'TIGER',
      },
      winner: '',
      burned: false,
      counterCards: 0, //INFO: 1 CARD, 2 DRAGON, 3 TIGER
    };
  },
  computed: {
    ...mapGetters({
      getGames: 'dragonTiger/getGames',
    }),
  },
  methods: {
    handleSelect(game) {
      this.gameSelected = game;
      this.gameUuid = game.uuid;
      this.removeSocket();
      this.createSocket();
    },
    removeSocket() {
      if (!this.socket) return;
      this.socket.disconnect();
      this.cleanBetInterval();
    },
    createSocket() {
      this.socket = io(this.SOCKET_URL, {
        transports: ['websocket'],
        query: {
          rouletteId: this.gameId,
          gameUuid: this.gameUuid,
          userId: this.PLAYER_ID,
        },
      });

      this.listenEvents();
    },
    listenEvents() {
      this.socket.on(`round:start`, (data) => {
        this.openRound(data);
      });

      this.socket.on(`round:end`, (data) => {
        const { winner } = data;
        this.winner = winner;
      });

      this.socket.on('cardToCrupier', (data) => {
        this.currentCard = true;
        const { type, name, color } = data.card;
        const { cardBurn, cardType, redCard } = data;
        this.cardType = cardType ? cardType : '';
        if (redCard) this.cardType = 'Roja';
        this.type = type;
        this.cardName = name;
        this.color = color;
        this.burned = cardBurn;
      });
    },
    openRound(data) {
      const { round } = data;
      const { start_date, end_date } = round;
      const initialTime = new Date(start_date);
      const endTime = new Date(end_date);
      this.totalTime = (endTime.getTime() - initialTime.getTime()) / 1000;
      const timeToBet = this.totalTime + 5;
      this.currentCard = false;
      setTimeout(() => {
        this.winner = '';
      }, 5000);

      this.generateInterval(timeToBet);
    },
    generateInterval(timeToBet) {
      this.roundClosed = false;
      this.roundOpened = true;
      this.timer = timeToBet;

      if (!this.timer && this.runningInterval) return this.cleanBetInterval();

      this.interval = setInterval(() => {
        if (!this.timer) return this.cleanBetInterval();

        this.runningInterval = true;
        this.timer--;
      }, this.timeToInterval);
    },
    cleanBetInterval() {
      this.timer = 0;
      clearInterval(this.interval);
    },
    async getDragonTigers() {
      try {
        await this.fetchGames();
        this.games = this.getGames;
      } catch (error) {
        console.log('ERROR GET GAMES', error);
      }
    },
    ...mapActions({
      fetchGames: 'dragonTiger/fetchGames',
    }),
  },
  mounted() {
    this.getDragonTigers();
    this.SOCKET_URL = process.env.VUE_APP_DRAGON_TIGER_API;
  },
  beforeDestroy() {
    this.removeSocket();
  },
};
</script>

<style scoped>
.card-accepted-container {
  display: grid;
  justify-content: center;
}

.card-accepted {
  background-color: rgb(231, 231, 231);
  box-shadow: 0 0 20px rgb(100, 100, 100);
  display: grid;
  border-radius: 5px;
  height: 250px;
  width: 150px;

  grid-template-columns: 30% 40% 30%;
  font-weight: bolder;
  font-size: 2rem;
  grid-template-rows: repeat(3, 33.3%);
}

.card-type {
  grid-row: 2/3;
  grid-column: 2/3;
  justify-self: center;
  font-size: 4rem;
  align-self: center;
}

.card-right-number {
  grid-row: 3/4;
  grid-column: 3/4;
  justify-self: end;
  align-self: end;
}

.winner-result {
  position: fixed;
  background: rgb(179, 180, 58);
  background: linear-gradient(
    90deg,
    rgb(179 180 58 / 0%) 0%,
    rgb(255 135 248) 20%,
    rgb(45 0 67) 80%,
    rgb(213 17 17 / 0%) 100%
  );
  width: 80%;
  left: 10%;
  height: 8rem;
  font-size: 7rem;
  font-weight: bolder;
  color: #fff;
  z-index: 9999;
  top: 0;
  text-shadow: -1px 4px #000000;
  animation-delay: 3s;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.winner-animation {
  animation-name: winnerAnimation;
  animation-iteration-count: 1;
}

@keyframes winnerAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
