<template>
  <div class="row py-4">
    <div class="col-2">
      <b-card
        v-for="(game, i) in getGamesByProvider"
        :key="i"
        @click="handleSelect(game)"
        style="cursor: pointer"
      >
        <p class="m-0">
          MESA: <strong>{{ game.provider }}</strong>
        </p>
        <p class="m-0">
          JUEGOS: <strong>{{ game.games }}</strong>
        </p>
      </b-card>
      <b-card v-if="phase">
        <h1 ref="winnerResult" class="text-center winner-result">
          <span> {{ this.$t(`phases.${phase}`) }}: </span>
          <span v-if="timer">
            {{ timer }}
          </span>
        </h1>
      </b-card>
      <b-card v-if="result">
        <h5>
          NUMERO SELECCIONADO: <strong>{{ result }}</strong>
        </h5>
        <div class="d-flex">
          <vs-button
            type="button"
            @click="handleResult(result)"
            :disabled="disableHandleResult"
            :loading="sending"
            >ENVIAR</vs-button
          >
          <vs-button type="button" success @click="result = null"
            >CANCELAR</vs-button
          >
        </div>
      </b-card>
      <b-card>
        <vs-button icon :disabled="!socket" @click="reloadSocket">
          <i class="uil-sync me-2"></i>
          ACTUALIZAR CONEXON
        </vs-button>
      </b-card>
    </div>
    <div class="col-10" v-if="selected">
      <b-overlay :show="show" rounded="sm">
        <b-card>
          <h4>MESA: {{ providerId }}</h4>
          <WheelNumbers @handleResult="handleNumber" />
        </b-card>
      </b-overlay>
    </div>
    <template v-else>
      <span>SELECCIONA UNA MESA</span>
    </template>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { io } from 'socket.io-client';
import { CHANGE_PHASE_EVENT } from '@/state/helpers/phases';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { WheelNumbers } from './components';

export default {
  components: { WheelNumbers },
  computed: {
    disableHandleResult() {
      if (typeof this.timer === 'number') return true;
      return false;
    },
    ...mapGetters({
      providers: 'wheel/getProviders',
      getGamesByProvider: 'wheel/getGamesByProvider',
      success: 'wheel/getSuccess',
      wheelPhase: 'wheel/getPhase',
    }),
  },
  data() {
    return {
      show: false,
      // SOCKET,
      socket: null,
      SOCKET_URL: '',
      gameId: '63ef9a172ef4d186807f80d5',
      gameUuid: '123456',
      PLAYER_ID: '123456',
      // SOCKET END,
      games: [],
      selected: false,
      providerId: '',
      phase: '',
      interval: null,
      timer: undefined,
      result: null,
      sending: false,
    };
  },
  methods: {
    async handleResult(number) {
      console.log('this.timer', this.timer);

      try {
        this.sending = true;
        this.show = true;
        await this.sendResult(number);
        console.log('data enviada');
        this.show = false;
        this.sending = false;
        Swal.fire({
          title: 'Resultado enviado',
          icon: 'success',
          timer: 2200,
        });
        this.result = null;
      } catch (error) {
        const { status, data } = error;
        console.log('data', data.phase === 'processing_next_round');

        if (data.phase === 'processing_next_round')
          return await this.reloadSocket();

        if (status < 500) return await this.sendPhase();

        Swal.fire({
          title: error.message,
          icon: 'error',
          timer: 5000,
        });
        this.show = false;
        this.sending = false;
      }
    },
    async sendResult(number) {
      try {
        const { status, data } = await this.$httpWheel.patch('round/result', {
          providerId: this.providerId,
          result: number,
        });
        if (status > 201)
          throw { message: 'Error enviando resultado', data, status };

        const { token } = data;
        this.updateTokenWheel(token);
      } catch (error) {
        console.log('error', error);
        throw error;
      }
    },
    async createRound() {
      try {
        const { data } = await this.$httpWheel.patch('round/start', {
          providerId: this.providerId,
        });

        console.log(data);
        const { PHASE } = data;

        if (PHASE === 'wait_result') return (this.phase = PHASE);
        // this.updateTokenWheel(token);
      } catch (error) {
        console.log('error create round', error);
      }
    },
    async sendPhase() {
      try {
        const phase = { providerId: this.providerId, phase: 'wait_result' };

        await Swal.fire({
          title: 'Cambiando fase espere e intente nuevamente',
          icon: 'info',
          timer: 2200,
        });

        await this.changePhase(phase);

        if (!this.success) throw { message: 'ERROR CAMBIANDO FASE', phase };

        Swal.fire({
          title: 'Fase cambiada. Intente nuevamente',
          icon: 'success',
          timer: 2200,
        });

        this.phase = this.wheelPhase;
        this.sending = false;
        this.show = false;
      } catch (error) {
        console.log('ERROR', error);
        Swal.fire({
          title: error.message,
          icon: 'error',
          timer: 5000,
        });
        this.show = false;
        this.sending = false;
      }
    },
    async reloadSocket() {
      this.removeSocket();
      this.createSocket();
      this.sending = false;
      this.show = false;
    },
    createSocket() {
      this.socket = io(this.SOCKET_URL, {
        transports: ['websocket'],
        query: {
          gameUuid: this.gameUuid,
          providerId: this.providerId,
          userId: this.PLAYER_ID,
          crupier: true,
        },
      });
      // this.emitEvents();
      this.listenEvents();
    },
    emitEvents() {
      this.socket.emit('crupier:connection', this.providerId);
    },
    listenEvents() {
      this.socket.on(`round:start`, (data) => {
        console.log('data start', data);
        // this.openRound(data);
      });
      this.socket.on(`round:end`, (data) => {
        console.log('data end', data);
        // this.openRound(data);
      });
      this.socket.on(CHANGE_PHASE_EVENT, ({ phase, timeWait }) => {
        console.log('PHASE', phase, timeWait);

        if (this.interval) clearInterval(this.interval);

        this.phase = phase;

        if (this.phase === 'bet_time') {
          if (timeWait) {
            this.timer = timeWait;
            this.createInteral();
          }
        } else {
          this.timer = undefined;
          clearInterval(this.interval);
        }
      });

      this.socket.on('disconnect', (data) => {
        console.log('SOCKET DESCONECTADO', data);
        this.phase = 'wait_result';
      });
    },
    createInteral() {
      this.interval = setInterval(() => {
        this.timer -= 1;
        console.log('this.timer', this.timer);
        if (this.timer === 0) {
          clearInterval(this.interval);
          return (this.timer = null);
        }
      }, 1000);
    },
    removeSocket() {
      if (!this.socket) return;
      this.socket.disconnect();
      // this.cleanBetInterval()
    },
    handleSelect(game) {
      const { provider } = game;

      if (this.providerId === provider) return;

      clearInterval(this.interval);
      this.providerId = provider;
      this.selected = true;
      this.timer = undefined;
      this.removeSocket();
      this.createSocket();
      this.createRound();
    },
    handleNumber(number) {
      this.result = number;
    },
    async configProviders() {
      await this.fetchProviders();
      await this.fetchWheels();
    },
    ...mapActions({
      fetchProviders: 'wheel/fetchProviders',
      fetchWheels: 'wheel/fetchWheels',
      changePhase: 'wheel/changePhase',
    }),
    ...mapMutations({
      updateTokenWheel: 'user/updateTokenWheel',
    }),
  },
  mounted() {
    this.SOCKET_URL = process.env.VUE_APP_WHEEL;
    this.configProviders();
  },
  beforeDestroy() {
    console.log('LIMPIAR INTERVALO Y SOCKET');
    clearInterval(this.interval);
    this.removeSocket();
  },
  watch: {
    timer(newVal) {
      if (newVal < 0) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
  },
};
</script>

<style scoped>
.winner-result {
  font-size: 2rem;
  font-weight: bolder;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
