<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos del operador"
      ref="pageForm"
      :items="items"
      v-if="isItForAdmin"
    >
    </PageHeader>
    <CrupierScreen v-if="userIsCrupier" :role="role" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-if="isItForAdmin">
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <b-form-input
                    v-model="filterData.fromDate"
                    type="date"
                    class="form-control-sm"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <b-form-input
                    v-model="filterData.toDate"
                    type="date"
                    class="form-control-sm"
                  ></b-form-input>
                </label>
              </div>
            </div>

            <div class="row my-4">
              <div class="col-sm-12 col-md-4">
                <label>{{ $t('filter.operator') }}:</label>
                <multiselect
                  :options="operators"
                  v-model="operator"
                  label="name"
                  placeholder="Selecciona un operador"
                  selectLabel=""
                  deselectLabel="Click para deseleccionar"
                  @input="(value) => handleSelection(value, 'Operator')"
                ></multiselect>
              </div>

              <div class="col-sm-12 col-md-4">
                <label>{{ $t('filter.roulette') }}:</label>
                <multiselect
                  :options="roulettes"
                  v-model="roulette"
                  label="name"
                  placeholder="Selecciona una ruleta"
                  selectLabel=""
                  deselectLabel="Click para deseleccionar"
                  @input="(value) => handleSelection(value, 'Roulette')"
                ></multiselect>
              </div>
            </div>
            <div class="d-flex">
              <vs-button>{{ $t('filter.filter') }}</vs-button>
              <vs-button
                success
                @click="cancelFilter()"
                :disabled="Object.entries(filterData).length ? false : true"
                >{{ $t('filter.cancel') }}</vs-button
              >
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg"> </b-overlay>
            <main-data :items="tableData"></main-data>
          </div>
          <ClientDashboard v-if="role === 'CLIENT'" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { StreamChat } from 'stream-chat';
import { mapActions, mapGetters } from 'vuex';
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header-simple';
import appConfig from '@/app.config';
import MainData from '../../../components/main-data.vue';
import CrupierScreen from '../Crupiers/CrupierScreen.vue';
import Multiselect from 'vue-multiselect';
import { breadCrumbData } from '../../../helpers/breadcrumbs/breadCrumbs';
import ClientDashboard from '../clients/components/ClientDashboard.vue';

/**
 * Users-table component
 */
export default {
  page: {
    title: 'Dasboard Table',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    MainData,
    Multiselect,
    CrupierScreen,
    ClientDashboard,
  },
  data() {
    return {
      role: '',
      userData: null,
      user: null,
      tableData: [],
      title: this.$t('sidebar.home'),
      items: breadCrumbData.home,
      loading: false,
      filterData: {},
      dataDetails: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      clients: [],
      operators: [],
      roulettes: [],
      sortBy: 'age',
      sortDesc: false,
      fields: [
        {
          key: 'username',
          sortable: true,
          label: this.$t('betDetail.user'),
        },
        {
          key: 'userId',
          sortable: true,
          label: this.$t('tableReports.userId'),
        },
        {
          key: 'operator',
          sortable: true,
          label: this.$t('tableReports.operator'),
        },
        {
          key: 'roundCount',
          sortable: true,
          label: 'Round Count',
        },
        {
          key: 'totalBet',
          sortable: true,
          label: this.$t('betDetail.totalBet'),
        },
        {
          key: 'totalWin',
          sortable: true,
          label: 'Total Win',
        },
        {
          key: 'profit',
          sortable: true,
          label: this.$t('tableReports.profit'),
        },
      ],

      clientFilter: null,
      operator: '',
      roulette: '',
      chatvalue: '',
      token: '',
      channel: {},
      client: {},
      messages: null,

      viewSelected: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    userDataAct() {
      return this.$store.dispatch('user/setUserAct');
    },
    ...mapGetters({
      mapOperators: 'operators/getOperators',
      mapGames: 'games/getGames',
      getViewSelected: 'crupiers/getViewSelected',
      isItForAdmin: 'user/onlyAdminRole',
      userIsCrupier: 'user/isCrupier',
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.getOperator();
    this.getRoulettes();
    this.getUserData();
  },
  methods: {
    async sendMessage() {
      await this.channel.sendMessage({
        text: this.chatvalue,
      });

      this.chatvalue = '';
    },
    async joinChat() {
      const { data } = await this.$http.post(`launch`, {
        casinoToken: '44840465-163a-42d4-ac6e-5f8551ecedc1',
        currency: 'USD',
        token: 'y42E68LY03fVL0rykRYrhmq00QmwnoCdqO66',
        casinoId: '6377d0e9bc2612755f975348',
        operatorId: '6377d0e9bc2612755f975361',
        language: 'es',
      });

      // this.username = "";
      // this.hasJoinedChat = true;
      this.user = { username: data.player.username };
      this.token = data.tokenChat;

      await this.initializeStream();
      await this.initializeChannel();
    },
    async initializeStream() {
      const { username } = this.user;

      this.client = new StreamChat(process.env.VUE_APP_CHAT_KEY);

      await this.client.setUser({ id: username, name: username }, this.token);
    },
    async initializeChannel() {
      this.channel = this.client.channel('livestream', 'vue-liveST-chat', {
        name: 'Vue LiveStrem Chat',
      });

      this.messages = (await this.channel.watch()).messages;
    },
    addValue(prop, value) {
      if (value) {
        return (this.filterData[prop] = value._id);
      }

      const { [prop]: type, ...rest } = this.filterData;

      this.filterData = rest;
      return type;
    },
    handleSelection(value, type) {
      switch (type) {
        case 'Operator':
          this.addValue('operadorId', value);
          break;
        case 'Roulette':
          this.addValue('rouletteId', value);
          break;
        default:
          break;
      }

      this.getData();
    },
    getClients() {
      this.$http
        .get('/clients')
        .then((response) => {
          this.clients = response.data.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getOperator() {
      try {
        await this.fetchOperators({ options: {} });
        this.operators = this.mapOperators;
      } catch (error) {
        console.log('ERROR GET OPERATOR', error);
        this.operators = [];
      }
    },
    filtrar() {
      this.loading = true;
      this.$http
        .get(`/reports/main-data`, {
          params: this.filterData,
        })
        .then((response) => {
          this.tableData = response.data.mainData;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelFilter() {
      this.operator = '';
      this.roulette = '';
      this.filterData = {};
      this.getData();
    },
    async getRoulettes() {
      try {
        await this.fetchGames({
          path: 'roulettes',
          options: {},
          type: 'roulettes',
        });
        this.roulettes = this.mapGames;
      } catch (error) {
        console.log('ERROR GET ROULETTES', error);
        this.roulettes = [];
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.loading = true;
      this.$http
        .get('/reports/main-data', {
          params: {
            ...this.filterData,
          },
        })
        .then((response) => {
          this.tableData = response.data.mainData;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getUserData() {
      const userData = this.$store.getters['user/getUserData'];
      this.role = this.$store.getters['user/getRole'];
      this.userData = userData;
    },
    async handleMenu() {
      await this.handleMenuOptions();
      this.viewSelected = this.getViewSelected;
    },
    ...mapActions({
      getUserinfo: 'user/fetchUserData',
      fetchOperators: 'operators/fetchOperators',
      fetchGames: 'games/findGames',
      handleMenuOptions: 'crupiers/handleMenuOptions',
    }),
  },
  middleware: 'authentication',
};
</script>

<style>
.space-nowrap {
  white-space: nowrap !important;
}
</style>
